import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { Card, Col, Container, Form, Row } from "reactstrap";
import useSweetAlert from "../../../Hooks/useSweetAlert";
import SweetAlert from "sweetalert2";
import PersonalInfo from "../../../Common/Forms/PersonalInfo";
import { useFormik } from "formik";
import { Btn } from "../../../AbstractElements";
import Timeline from "../../Timeline";
import CourseForm from "../../../Common/Forms/CourseForm";
import PasswordForm from "../../../Common/Forms/PasswordForm";
import { EditFormValidation, onboardingValidation } from "../../../Validation";
import {
  getNextEnrollmentCount,
  login,
  reAuthenticateUser,
  updateStudentEmail,
  uploadImage,
  userUpdate,
} from "../../../Service/Firebase";
import { register } from "../../../Service/Firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader";
import { EDIT_PROFILE, ENROLLMENT_ID } from "../../../Constant";
import studentContext from "../../../Helper/Student";

const initialValues = {
  fullName: "",
  age: "",
  gender: "",
  qualification: "",
  cnicNo: "",
  contactNo: "",
  userImage: "",
  userImagePreview: "",
  howYouKnow: "",
  ownLaptop: "",
  policyAgreed: false,
  courseId: "",
  password: "",
  cPassword: ""
};

const OnBoarding = ({ type, toggler }) => {
  const { showSweetAlert } = useSweetAlert();
  const [step, setStep] = useState(+localStorage.getItem("onBoardFormNo") ?? 0);
  const { studentDetails, setStudentDetails, setLoading } = useContext(studentContext);
  const navigate = useNavigate();
  const userEmail =  window !== undefined && localStorage.getItem("onBoardingEmail");
  const isEditForm = type === EDIT_PROFILE;

  const renderForm = (formik) => {
    switch (step) {
      case 0:
        return <PersonalInfo formik={formik} type={type} />;
      case 1:
        return <CourseForm formik={formik} />;
      case 2:
        return <PasswordForm formik={formik} showHeading={true} />;
      default:
        return null;
    }
  };

  const clearOnboardLocalStoarge = () => {
    localStorage.removeItem("onBoardFormNo");
    localStorage.removeItem("onBoardData");
    localStorage.removeItem("onBoardingEmail");
  };

  const userOnboardSuccess = async (userEmail, data, setSubmitting) => {
    await userUpdate(userEmail ? userEmail : studentDetails.email, data);
    setSubmitting(false);
    if (isEditForm) {
      toast.success("Profile Edit successfully");
      setStudentDetails(data);
      toggler();
    } else {
      toast.success("Profile Setup successfully");
      setTimeout(() => {
        clearOnboardLocalStoarge();
        setLoading(true)
        navigate("/dashboard");
        localStorage.setItem("login", true);
      }, 1000);
    }
  };

  const onBack = () => {
    if (step !== 0) {
      setStep(step - 1);
      localStorage.setItem("onBoardFormNo", step - 1);
    }
  };

  const onNext = () => {
    if (step !== 2) {
      setStep(step + 1);
      localStorage.setItem("onBoardData", JSON.stringify(formik.values));
      localStorage.setItem("onBoardFormNo", step + 1);
    }
  };

  const handleEditProfileError = (error) => {
    switch (error.message) {
      case "auth/requires-recent-login":
        setStep(2)
        toast.error('Email change requires recent authentication. Please enter password again to proceed');
        break;
      case "auth/wrong-password":
        toast.error('Invalid Password');
        break;
      case "auth/email-already-in-use":
        toast.error('The email address is already in use by another account.');
        break;
      default:
        break;
    }
  }


  const saveUpdatedStudentData = async (values, setSubmitting) => {
    try {
      const passwordSetup = step === 2;
      let imagePreviewUrl;

      if (values?.userImage) {
        imagePreviewUrl = await uploadImage(values.userImage, 'userProfile');
      }

      if (passwordSetup) {
        await reAuthenticateUser(studentDetails.email, values.password);
      }

      const {
        courseIndex,
        userImage,
        userImagePreview,
        ...studentUpdatedValue
      } = values;
      const editEmail = values.email.trim().toLowerCase();
      await updateStudentEmail(editEmail, studentDetails, studentUpdatedValue);


      studentUpdatedValue.userProfileImage = imagePreviewUrl || studentUpdatedValue.userProfileImage;
      await userOnboardSuccess(studentDetails.email, studentUpdatedValue, setSubmitting);
    } catch (error) {
      setSubmitting(false);
      handleEditProfileError(error);
    }
  }

  const uploadUserProfileImage = async (userImage) => {
    try {
      const userImageUrl = await uploadImage(userImage, 'userProfile');
      return userImageUrl;
    } catch (error) {
      throw error;
    }
  }

  const enrollStudent = async (values) => {
    try {
      const { userImage, courseId, password, cPassword, ...studentData } = values;

      const userImageUrl = await uploadUserProfileImage(userImage);
      const enrollmentCount = await getNextEnrollmentCount();

      const coursesData = { courseId, feeScreenshots: [], enrolledAt: new Date() };
      const data = {
        ...studentData,
        createdAt: new Date(),
        studentId: enrollmentCount,
        userProfileImage: userImageUrl,
        courseIds: [courseId],
        courses: [coursesData],
      }

      return { data, password };
    } catch (error) {
      throw error;
    }
  }

  const saveOnboardStudentData = async (values, setSubmitting) => {
    try {
      const { password, data } = await enrollStudent(values);

      const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(userEmail);

      if (signInMethods.length > 0) {
        await firebase.auth().signInWithEmailAndPassword(userEmail, password)
          .then(async (userCredential) => {
            data.uid = userCredential.user.uid;
            await userOnboardSuccess(userEmail, data, setSubmitting);
          })
          .catch((error) => {
            setSubmitting(false);
            showSweetAlert(
              "Incorrect Password",
              `We're sorry, but the email you entered (${userEmail}) already has an existing account. Please reset your password and complete the onboarding process again.`,
              "Forget Password",
              "error",
              "/forgot-password",
              true,
              clearOnboardLocalStoarge
            );
          });
      } else {
        const user = await register(userEmail, password);
        const userId = user?.user?.uid;
        if (userId) {
          data.uid = userId;
          await userOnboardSuccess(userEmail, data, setSubmitting);
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const saveEnrollmentData = async (values, setSubmitting) => {
    if (isEditForm) {
      saveUpdatedStudentData(values, setSubmitting)
    } else {
      saveOnboardStudentData(values, setSubmitting)
    }
  };

  const formSubmitHandler = (values, { setSubmitting }) => {
    if (step !== 2 && !isEditForm) {
      setSubmitting(false);
      onNext();
    } else {
      saveEnrollmentData(values, setSubmitting);
    }
  };

  const formik = useFormik({
    initialValues:
      type === EDIT_PROFILE
        ? studentDetails
        : JSON.parse(localStorage.getItem("onBoardData")) ?? initialValues,
    validationSchema:
      type === EDIT_PROFILE ? EditFormValidation : onboardingValidation[step],
    onSubmit: formSubmitHandler,
    enableReinitialize: true,
  });


  const btnText = (formik) => {
    if (formik.isSubmitting) {
      return <Loader />;
    }

    if (isEditForm) {
      return "Save";
    }

    if (step > 1) {
      return "Submit";
    }

    return 'Next';
  }

  const renderFormActionBtn = () => {
    const flexDirection = (step > 0 || isEditForm) ? "justify-content-between" : "justify-content-end"
    return (<div className={`p-t-10 d-flex align-items-center ${flexDirection}`}>
      {(step !== 0 || isEditForm) && (
        <Btn attrBtn={{
          color: "secondary",
          disabled: formik.isSubmitting,
          onClick: isEditForm ? toggler : onBack,
        }}>
          {isEditForm && !step !== 2 ? "Close" : "Back"}
        </Btn>
      )}
      <Btn attrBtn={{
        color: "primary",
        type: "submit",
        disabled: formik.isSubmitting,
      }}>
        {btnText(formik)}
      </Btn>
    </div>)
  }

  useEffect(() => {
    if (!userEmail && type !== EDIT_PROFILE) navigate('/login')
  }, [])

  return (
    <Container fluid={true}>
      <Row
        className={`d-flex align-items-center justify-content-center full-height-form ${isEditForm ? "p-0 h-auto" : ""}`} >
        <Col
          md={isEditForm ? 12 : 6}
          xl={isEditForm ? 12 : 5}
          xxl={isEditForm ? 12 : 4}
          sm={12}
          className={isEditForm ? "p-0" : "m-t-10"}
        >
          {!isEditForm && <Timeline step={step} />}
          <Card className={`card-padding ${isEditForm ? "m-b-0 px-0 p-0" : ""} `}>
            <Form onSubmit={formik.handleSubmit}>
              {renderForm(formik)}

              {renderFormActionBtn()}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OnBoarding;
