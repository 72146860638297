import firebase from "firebase";
import { toast } from "react-toastify";
import SweetAlert from 'sweetalert2'

// import swal2 from "sweetalert";
// import "firebase/compat/messaging";
import { getBrowserInfo } from "../utils/browserInfo";
// import { getRandomNumber } from "../utils/randomNumber";
import getFirebaseConfig from "./config";
// import History from "./history";
import { v4 as uuidv4 } from "uuid";
import { ENROLLMENT_ID } from "../Constant";

require("firebase/firestore");

const firebaseConfig = getFirebaseConfig()
firebase.initializeApp(firebaseConfig
);

//Initialize Firestore
const db = firebase.firestore();
const settings = { timestampsInSnapshots: true };
db.settings(settings);
db.enablePersistence().catch((err) => {
  addCrashErrorToDB("enablePersistence", err, {}, currentUserEmail);
});
const currentUserEmail = firebase.auth()?.currentUser?.email;
// const storage = firebase.storage();

const getNextEnrollmentCount = async () => {
  try {
    let enrollmentCount;
    const db = firebase.firestore();
    await db.runTransaction(async (transaction) => {
      const docRef = db.collection("generalInfo").doc(ENROLLMENT_ID);
      const doc = await transaction.get(docRef);

      if (!doc.exists) {
        throw new Error("Document does not exist");
      }

      enrollmentCount = doc.data().enrollmentCount + 1;
      transaction.update(docRef, { enrollmentCount });
    })

    return enrollmentCount;
  } catch (error) {
    console.error("Error updating enrollment count:", error);
    throw new Error("Failed to get the next enrollment count.")
  }
}

const category = () => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .get()
      .then((snapshot) => {
        let cat = [];
        snapshot.forEach(function (doc) {
          let obj = {};
          if (doc.exists) {
            obj.id = doc.id;
            obj.data = doc.data();
            cat.push(obj);
          } else {
            reject(cat);
          }
        });
        let promises = cat.map((res) => {
          return new Promise((resolve, reject) => {
            db.collection("categories")
              .doc(res.id)
              .collection("Quizzes")
              .get()
              .then((snapshot) => {
                let quizzes = [];
                snapshot.forEach(function (doc) {
                  let obj = {};
                  if (doc.exists) {
                    obj = doc.data();
                    obj.id = doc.id;
                    quizzes.push(obj);
                  }
                });
                res.quizzes = quizzes;
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                addCrashErrorToDB(
                  "category",
                  error.message,
                  {},
                  currentUserEmail
                );
              });
          });
        });
        resolve(Promise.all(promises));
      });
  });
};

const forgetpswrd = (email) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        resolve("done");
      })
      .catch((err) => {
        reject(err);
        console.log(err.message);
        addCrashErrorToDB("forgetpswrd", err.message, { email }, email);
      });
  });
};

const changePassword = (oobCode, newPassword) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .confirmPasswordReset(oobCode, newPassword)

      .then((res) => {
        resolve("done");
      })
      .catch((err) => {
        reject(err);
        console.log(err);
        addCrashErrorToDB(
          "changePassword",
          err.message,
          { oobCode, newPassword },
          currentUserEmail
        );
      });
  });
};

const getCourseCollection = () => {
  return (
    db
      .collection("courses")
      // .where("isCurrent", "==", true)
      .get()
      .then(function (querySnapshot) {
        const courses = [];
        querySnapshot.forEach((snap) => {
          courses.push({ id: snap.id, ...snap.data() });
        });
        return courses;
      })
      .catch((error) => {
        console.log(error);
        addCrashErrorToDB(
          "getCourseCollection",
          error.message,
          {},
          currentUserEmail
        );

        // console.log("Error getting documents: ", error);
      })
  );
};

const getAttendanceByEmail = async (email) => {
  return db
    .collection("attendance")
    .where("userId", "==", email)
    .orderBy("createdAt", "desc")
    .get()
    .then(function (querySnapshot) {
      const attendanceDocs = [];
      querySnapshot.forEach((snap) => {
        attendanceDocs.push({ id: snap.id, ...snap.data() });
      });
      return attendanceDocs;
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
};

const getAllCourses = () => {
  return db
    .collection("courses")
    .get()
    .then(function (querySnapshot) {
      const courses = [];
      querySnapshot.forEach((snap) => {
        courses.push({ id: snap.id, ...snap.data() });
      });
      return courses;
    })
    .catch((error) => {
      console.log(error);
      addCrashErrorToDB("getAllCourses", error.message, {}, currentUserEmail);
      // console.log("Error getting documents: ", error);
    });
};

const getCategory = () => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .get()
      .then((snapshot) => {
        let cat = [];
        snapshot.forEach(function (doc) {
          let obj = {};
          if (doc.exists) {
            obj.id = doc.id;
            obj.data = doc.data();
            cat.push(obj);
          } else {
            reject(cat);
          }
        });
        resolve(cat);
      })
      .catch((error) => {
        console.log(error);
        addCrashErrorToDB("getCategory", error.message, {}, currentUserEmail);
      });
  });
};
const addAdminActivity = async (currenUser, data) => {
  if (!currenUser.isAdmin) return;
  const browserName = getBrowserInfo();
  const osName = navigator.platform;
  try {
    data.browserName = browserName;
    data.osName = osName;
    data.coordination = {};
    const apiKey = process.env.REACT_APP_TRELLO_API_KEY;
    const apiToken = process.env.REACT_APP_TRELLO_API_TOKEN;
    const listId = process.env.REACT_APP_TRELLO_ACTIVITY_API_LIST_ID;
    // Set up the API URL
    const url = `https://api.trello.com/1/cards?key=${apiKey}&token=${apiToken}`;
    // Set up the request payload
    const payload = {
      name: data.acivity,
      desc: `
      Activity: ${data.acivity}
      AdminId: ${data.adminId}
      Actvity Type: ${data.type}
      Browser Name: ${data.browserName}
      Latitude: ${data?.coordination?.lat ?? "N/A"}
      Longitude: ${data?.coordination?.long ?? "N/A"} 
      Os Name: ${data.osName}`,
      idLabels: ["6481a83c1afdaf2b058915b6"],
      idList: listId,
    };
    await db.collection('adminActivities').add(data)
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    console.log("Activity Tracked");
  } catch (error) {
    console.log(error);
  }
};
const addStudentActivity = async (data) => {
  try {
    await db.collection('studentActivities').add(data)
  } catch (error) {
    console.log(error)
  }
}

const googleLogin = () => {
  return new Promise((resolve, reject) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // Accounts successfully linked.
        // var credential = result.credential;
        var user = result.user;
        db.collection("users").doc(user.uid).set({
          id: user.uid,
          name: user.displayName,
          email: user.email,
        });
      });
  }).catch((error) => {
    console.log(error);
  });
};

const facebookLogin = (email, displayName) => {
  return new Promise((resolve, reject) => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var user = result.user;
        db.collection("users").doc(user.uid).set({
          id: user.uid,
          name: user.displayName,
          email: user.email,
        });
      })
      .catch(function (error) {
        // console.log(error);
        // swal2("Error", error.message, "error");
      });
  });
};

const register = async (email, password) => {
  try {
    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    return user;
  } catch (error) {
    toast.error(error.message);
    addCrashErrorToDB(
      "register",
      error.message,
      { email, password },
      currentUserEmail
    );
  }
};
const getTotalStudents = async () => {
  try {
    const users = await db.collection("users").orderBy("studentId").get();
    return users.docs.map((user) => ({ id: user.id, ...user.data() }));
  } catch (error) {
    addCrashErrorToDB("getTotalStudents", error.message, {}, currentUserEmail);
  }
};

const getStudentAttendance = async (userEmail) => {
  try {
    console.log("currentUserEmail");
    const attendance = await db
      .collection("attendance")
      .where("userId", "==", userEmail)
      .orderBy("createdAt", "desc")
      .get();
    return attendance.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    addCrashErrorToDB("getStudentAttendance", error.message, {}, userEmail);
  }
};


const createStudentCollection = async (email) => {
  try {
    const documentRef = db.collection("users").doc(email);
    const isExist = await documentRef.get();
    if (isExist.data()?.email) {
      return isExist.data();
    } else {
      const courses = await getCourseTimings()
      const isCourseAdmissionOpen = courses.find((cour) => cour.admissionOpen)
      if (isCourseAdmissionOpen) {
        const createdCol = await documentRef.set({
          email: email,
          courses: [],
        });
        return true;
      }else {
        SweetAlert.fire('Sorry!', 'Currently, we do not have any open admissions for any courses. Please stay tuned for future updates')
      }
    }
  } catch (error) {
    console.log(error);
    addCrashErrorToDB(
      "createStudentCollection",
      error.message,
      { email },
      currentUserEmail
    );
  }
};

const firebaseGetNotificationPermission = async (email) => {
  const messaging = firebase.messaging();
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();
    await firebase
      .firestore()
      .collection("users")
      .doc(email)
      .update({
        deviceTokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
  } catch (error) {
    if (!localStorage.getItem('notification-permission')) {
      toast.error("Notification permission not granted");
      localStorage.setItem('notification-permission', true)
      addCrashErrorToDB("getStudentId", error.message, {}, currentUserEmail);
    }
  }
};

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        resolve(res);
        //  getStudent(res.user.email).then((currUser)=> {
        //   addAdminActivity(currUser, activityOptions)
        // })
      })
      .catch((error) => {
        reject(error);
        console.log(error);
        // toast.error("Please enter valid credentials");
        //  addCrashErrorToDB(
        //   "login",
        //   error.message,
        //   { email, password },
        //   currentUserEmail
        // );
      });
  });
};

const sendVerificationEmail = async () => {
  try {
    await firebase.auth().currentUser.sendEmailVerification()
    console.log("Email Send")
  } catch (error) {
    throw new Error(error.message)
  }
}

const confirmEmail = async (code) => {
  try {
    const info = await firebase.auth().checkActionCode(code);
    const email = info?.data.email
    await firebase.auth().applyActionCode(code)
    await userUpdate(email, { emailVerified: true })
  } catch (error) {
    throw new Error(error.message)
  }
}
const getUserNotification = async (email) => {
  try {
    const userNotifications = await db
      .collection("notifications")
      .where("userId", "==", email)
      .orderBy('createdAt', 'desc')
      .get();
    const notifications = userNotifications.docs.map((notifi) => ({
      ...notifi.data(),
      id: notifi.id,
    }));
    return notifications;
  } catch (error) {
    addCrashErrorToDB(
      "getUserNotification",
      error.message,
      {},
      currentUserEmail
    );
  }
};

const uploadImage = (image, imagePath) => {
  const uniqueName = image?.name ? `${uuidv4()}_${image.name}` : `${Date.now()}_${uuidv4()}.png`;

  return new Promise((resolve) => {
    const path = firebase.storage().ref(`${imagePath}/` + uniqueName);

    if (!image?.name) {
      path.putString(image, 'data_url')
        .then(() => path.getDownloadURL())
        .then((url) => resolve(url))
        .catch((error) => {
          console.log(error);
          addCrashErrorToDB("uploadImage", error.message, image, currentUserEmail);
        });
    } else {
      path.put(image)
        .then(() => path.getDownloadURL())
        .then((url) => resolve(url))
        .catch((error) => {
          console.log(error);
          addCrashErrorToDB("uploadImage", error.message, image, currentUserEmail);
        });
    }
  });
};

// const uploadImage = (image, imagePath) => {
//   const imageName = image?.name ? image.name : `${Date.now()}.png`.toString()
//   return new Promise((resolve) => {
//     const path = firebase.storage().ref(`${imagePath}/` + imageName);

//     !image?.name ?
//       path.putString(image, 'data_url') :
//       path.put(image)
//         .then(function () {
//           path.getDownloadURL().then(function (url) {
//             resolve(url);
//           });
//         })
//         .catch((error) => {
//           console.log(error);
//           addCrashErrorToDB(
//             "uploadImage",
//             error.message,
//             image,
//             currentUserEmail
//           );
//         });
//   });
// }

const deleteImage = async (imageURL) => {
  if (!imageURL) return;
  try {
    const imageRef = firebase.storage().ref().child(imageURL);
    await imageRef.delete();
  } catch (error) {
    console.log(error);
    addCrashErrorToDB("deleteImage", error.message, imageURL, currentUserEmail);
  }
};
const deleteCatgory = async (categoryId) => {
  if (!categoryId) return;
  try {
    const docRef = firebase
      .firestore()
      .collection("categories")
      .doc(categoryId);
    await docRef.delete();
  } catch (error) {
    console.log(error);
    addCrashErrorToDB(
      "deleteCatgory",
      error.message,
      categoryId,
      currentUserEmail
    );
  }
};
const editCatgory = async (categoryId, data) => {
  if (!categoryId) return;
  try {
    const docRef = firebase
      .firestore()
      .collection("categories")
      .doc(categoryId);
    await docRef.update({
      name: data.category,
      module: data.description,
    });
  } catch (error) {
    console.log(error);
    addCrashErrorToDB(
      "editCatgory",
      error.message,
      categoryId,
      currentUserEmail
    );
  }
};

const saveImageText = (data) => {
  if (!data.text) return;
  return new Promise((resolve, reject) => {
    db.collection("screenshotText")
      .add(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error.message);
        addCrashErrorToDB(
          "saveImageText",
          error.message,
          data,
          currentUserEmail
        );
      });
  });
};

const getImageText = () => {
  return new Promise((resolve, reject) => {
    db.collection("screenshotText")
      .get()
      .then((res) => {
        const imageTextList = [];
        res.docs.forEach((doc) =>
          imageTextList.push({ id: doc.id, ...doc.data() })
        );
        resolve(imageTextList);
      });
  });
};

const addCategories = (category, description) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .add({
        name: category,
        createdAt: Date.now(),
        module: description,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
        addCrashErrorToDB(
          "addCategories",
          error.message,
          { category, description },
          currentUserEmail
        );
      });
  });
};

const userUpdate = async (email, params) => {
  try {
    await db.collection("users").doc(email.toLowerCase()).update(params);
  } catch (error) {
    console.log(error.message);
    addCrashErrorToDB("userUpdate", error.message, params, email);
  }
};

const updateUserProfile = (fName, lName, batch, userId) => {
  return db.collection("users").doc(userId).update({
    first_name: fName,
    last_name: lName,
    batch: batch,
  });
};

const addCrashErrorToDB = async (apiName, errorMessage, data, email) => {
  const browserName = getBrowserInfo();
  // const browserName = '';
  const osName = navigator.platform;
  console.log(apiName);
  console.log(errorMessage);
  try {
    await db.collection("crashErrors").add({
      email: email ?? "Without login",
      params: data,
      errorMessage,
      functionName: apiName,
      createdAt: new Date(),
      browserName,
      osName,
    });
  } catch (error) {
    console.log(error);
  }
};

const updateUserResult = async (updatedArray, userId) => {
  try {
    await db.collection("users").doc(userId).update({
      result: updatedArray,
    });
  } catch (error) {
    console.log(error);
    addCrashErrorToDB(
      "updateUserResult",
      error.message,
      updatedArray,
      currentUserEmail
    );
  }
};

const preResult = (userId) => {
  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((res) => {
        resolve(res.data());
      })
      .catch((err) => {
        console.log(err);
        reject(err);
        addCrashErrorToDB(
          "updateUserResult",
          err.message,
          userId,
          currentUserEmail
        );
      });
  });
};

const userResult = (userId) => {
  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((res) => {
        resolve(res.data());
      })
      .catch((err) => {
        reject(err);
        console.log(err);
        addCrashErrorToDB("userResult", err.message, userId, currentUserEmail);
      });
  });
};

const getQuizes = (quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(quizId)
      .collection("Quizzes")
      .get()
      .then((snapshot) => {
        let quizzes = [];
        snapshot.forEach((doc) => {
          var obj = {};
          obj.data = doc.data();
          obj.id = doc.id;
          quizzes.push(obj);
        });
        resolve(quizzes);
      })
      .catch((error) => {
        console.log(error);
        addCrashErrorToDB("getQuizes", error.message, quizId, currentUserEmail);
      });
  });
};

const addQuizData = (
  categoryId,
  quizMark,
  quizName,
  quizDuration,
  quizPassword
) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .add({
        Duration: quizDuration,
        maxMarks: quizMark,
        name: quizName,
        password: quizPassword,
        createdAt: Date.now(),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error.message);
        addCrashErrorToDB(
          "addQuizData",
          error.message,
          { categoryId, quizMark, quizName, quizDuration, quizPassword },
          currentUserEmail
        );
      });
  });
};

const deleteSubCategory = async (mainId, subId) => {
  try {
    const category = db
      .collection("categories")
      .doc(mainId)
      .collection("Quizzes")
      .doc(subId);
    await category.delete();
  } catch (error) {
    addCrashErrorToDB(
      "deleteSubCategory",
      error.message,
      { mainId, subId },
      currentUserEmail
    );
  }
};

const editSubCategory = async (mainId, subId, quizName) => {
  try {
    const category = db
      .collection("categories")
      .doc(mainId)
      .collection("Quizzes")
      .doc(subId);
    await category.update({
      name: quizName,
    });
  } catch (error) {
    addCrashErrorToDB(
      "editSubCategory",
      error.message,
      { mainId, subId },
      currentUserEmail
    );
  }
};

const getQuizQuestion = (categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .get()
      .then((res) => {
        resolve(res.data());
      })
      .catch((error) => {
        console.log(error);
        addCrashErrorToDB(
          "getQuizQuestion",
          error.message,
          { categoryId, quizId },
          currentUserEmail
        );
      });
  });
};

const addQuestion = (allQuestion, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        questions: allQuestion,
      });
    resolve();
  });
};

const deleteQuestion = (newQuestion, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        questions: newQuestion,
      });
    resolve();
  });
};

const updateQuestion = (allQuestion, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        questions: allQuestion,
      });
    resolve();
  });
};

const resetPassword = (curPsw, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        password: curPsw,
      });
    resolve();
  });
};
const changeReviewOpen = (review, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        showReview: review,
      });
    resolve();
  });
};

const updateDuration = (Duration, categoryId, quizId) => {
  return new Promise((resolve, reject) => {
    db.collection("categories")
      .doc(categoryId)
      .collection("Quizzes")
      .doc(quizId)
      .update({
        Duration,
      });
    resolve();
  });
};

const getStudentId = async () => {
  try {
    const docRef = await db
      .collection("generalInfo")
      .doc("YQVo7lnENx4mlDevJVVm")
      .get();
    return docRef.data();
  } catch (error) {
    console.log(error);
    addCrashErrorToDB("getStudentId", error.message, {}, currentUserEmail);
  }
};
const getPaymentDetails = async () => {
  try {
    const docRef = await db
      .collection("generalInfo")
      .doc("dy89z5Veirs4IoclGrUI")
      .get();
    return docRef.data();
  } catch (error) {
    console.log(error);
    addCrashErrorToDB("getPaymentDetails", error.message, {}, currentUserEmail);
  }
};
const getUniversityPolicies = async () => {
  try {
    const docRef = await db
      .collection("generalInfo")
      .doc("hSQJSC5iyiekZm2zcT63")
      .get();
    return docRef.data();
  } catch (error) {
    console.log(error);
    addCrashErrorToDB("getUniversityPolicies", error.message, {}, currentUserEmail);
  }
};


const changeNotificationStatus = async (id) => {
  try {
    const res = await db.collection('notifications').doc(id).update({ read: true })
  } catch (error) {
    addCrashErrorToDB(
      "changeNotificationStatus",
      error.message,
      {},
      currentUserEmail
    );
  }
}

const getCourseTimings = () => {
  return new Promise((resolve, reject) => {
    db.collection("courses")
      .get()
      .then((res) => {
        const courses = [];
        res.docs.forEach((doc) => courses.push({ id: doc.id, ...doc.data() }));
        resolve(courses);
      })
      .catch((error) => {
        console.log(error);
        addCrashErrorToDB(
          "getCourseTimings",
          error.message,
          {},
          currentUserEmail
        );
      });
  });
};

const addNotificationToDb = async ({ title, body, type, email }) => {
  try {
    const notification = {
      title,
      body,
      type,
      userId: email,
      read: false,
      createdAt: new Date(),
    };
    const res = await db.collection("notifications").add(notification);
    return { ...notification, id: res.id };
  } catch (error) {
    addCrashErrorToDB(
      "addNotificationToDb",
      error.message,
      {},
      currentUserEmail
    );
  }
};
const updateStudentEmail = async (newEmail, studentDetails, studentUpdatedValue) => {
  try {
    if (newEmail === studentDetails.email.trim().toLowerCase()) return
    await firebase.auth().currentUser.updateEmail(newEmail)
    await db.collection('users').doc(studentDetails.email).delete()
    await db.collection('users').doc(newEmail).set({
      ...studentDetails,
      email: newEmail,
      emailVerified: false,
      id: newEmail
    })
    studentUpdatedValue.emailVerified = false
    const activityOptions = {
      studentEmail: studentDetails.email,
      type: "email_change",
      createdAt: new Date(),
      activity: `${studentDetails.fullName} has changed their email from ${studentDetails.email} to ${newEmail}`
    };
    await addStudentActivity(activityOptions)
  } catch (error) {
    addCrashErrorToDB(
      "updateStudentEmail",
      error.message,
      { code: error.code },
      currentUserEmail
    );
    throw new Error(error.code)
  }
}

const getStudent = (userId) => {
  if (userId) {
    return new Promise((resolve, reject) => {
      db.collection("users")
        .doc(userId)
        .get()
        .then((res) => {
          resolve(res.data());
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          addCrashErrorToDB(
            "getStudent",
            err.message,
            userId,
            currentUserEmail
          );
        });
    });
  }
};

const isUserExist = async (email) => {
  try {
    const userDetail = await db
      .collection("users")
      .where("email", "==", email)
      .get();
    return userDetail.docs.length;
  } catch (error) {
    addCrashErrorToDB("isUserExist", error.message, {}, currentUserEmail);
  }
};

const reAuthenticateUser = async (email, password) => {
  try {
    const credential = await firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    await firebase.auth().currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
  } catch (error) {
    throw new Error(error.code)
  }
}

const sendMail = (email, fullName, courseName, courseType, courseModule) => {
  const sendEmail = firebase.functions().httpsCallable("sendEmail");
  sendEmail({
    toEmail: email,
    feeStatus: "pending",
    name: fullName,
    courseName,
    courseType,
    courseModule,
  })
    .then((result) => {
      // Handle the result of the function call
    })
    .catch((error) => {
      // Handle any errors that occurred during the function call
    });
};

const addReport = async (screenshot, email, description) => {
  try {
    const bugScreenShot = await uploadImage(
      screenshot,
      "bugScreenShots"
    );
    await addCrashErrorToDB(
      "Report a bug",
      description,
      { bugScreenShot },
      email
    );

    const apiKey = 'fb1769030518da07dc4e3fe6a2aae15b';
    const apiToken = 'ATTA1a4555ac1beca91c652bf7f8314d333a3e6c1f4c7868cccbd76423a1f335b83e2B6B2789';
    const listId = '63ef8887ac81258730727274';

    // Set up the API URL
    const url = `https://api.trello.com/1/cards?key=${apiKey}&token=${apiToken}`;

    // Set up the request payload
    const payload = {
      name: email,
      desc: description,
      idLabels: ["6363b7f61c38c7538df14add"],
      idList: listId,
      urlSource: bugScreenShot,
    };

    // Make the API request using fetch
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  } catch (error) {
    throw new Error(error.message)
  }
}

export {
  sendMail,
  firebase,
  getStudent,
  category,
  addReport,
  register,
  updateDuration,
  resetPassword,
  login,
  updateUserResult,
  updateQuestion,
  addCategories,
  changePassword,
  preResult,
  getQuizes,
  getTotalStudents,
  addQuizData,
  getCategory,
  forgetpswrd,
  uploadImage,
  getQuizQuestion,
  addQuestion,
  deleteImage,
  getCourseCollection,
  saveImageText,
  getAllCourses,
  getImageText,
  deleteQuestion,
  confirmEmail,
  getStudentAttendance,
  userUpdate,
  reAuthenticateUser,
  userResult,
  getStudentId,
  deleteCatgory,
  deleteSubCategory,
  getPaymentDetails,
  createStudentCollection,
  addStudentActivity,
  googleLogin,
  firebaseGetNotificationPermission,
  facebookLogin,
  getUniversityPolicies,
  updateStudentEmail,
  changeReviewOpen,
  addAdminActivity,
  changeNotificationStatus,
  addNotificationToDb,
  isUserExist,
  editCatgory,
  editSubCategory,
  updateUserProfile,
  getUserNotification,
  getCourseTimings,
  addCrashErrorToDB,
  sendVerificationEmail,
  getAttendanceByEmail,
  getNextEnrollmentCount,
};
