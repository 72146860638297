import { Label, Input, Spinner } from "reactstrap";
import { H4 } from "../../../AbstractElements";

const Dropzone1 = ({ imageChangeHandler, imageMessage, isModelLoaded, images, isMultiple = false, name }) => {
  return (
    <div className="custom-dropzone">
      <Label
        htmlFor="file-upload"
        className={
          images?.length > 1
            ? "half-image-conatiner"
            : "" + "w-100 h-100 file-upload-lebel"
        }
      >
        {isMultiple ? (
          images.length ? (
            images.map((img, index) => (
              <img
                key={index}
                className={images.length > 1 ? "half-image" : ""}
                src={URL.createObjectURL(img)}
                alt=""
              />
            ))
          ) : (
            <H4>Drop A File</H4>
          )
        ) : isModelLoaded ? (
          <div className="message-container ">
            <p className="loading-text">It may take a moment</p>
            <Spinner attrSpinner={{ className: "loader-image" }} />
          </div>
        ) : ( imageMessage || imageMessage == "0" ) && !images ? (
          <div className="message-container">
            <p className="message-text error-text">
              {imageMessage} faces detected. A clear image with exactly one face
              is acceptable.
            </p>
          </div>
        ) : images ? (
          <img src={images} alt="" />
        ) : (
          <H4>Drop A File</H4>
        )}
      </Label>
      <Input
        type="file"
        multiple={isMultiple}
        accept=".jpg, .jpeg, .png"
        hidden
        id="file-upload"
        name={name}
        onChange={imageChangeHandler}
      />
    </div>
  );
};

export default Dropzone1;
